import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getCemFeatures, getUserInfo } from '../../store/userInfo/userInfoSlice'
import useTracking from './useTracking'
import { isCemFeatureEnabled } from '../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
} from '../../utils/constants/constants'

const TrackingManager = () => {
  const [dateConsentChanged, setDateConsentChanged] = useState<Date | null>(
    null,
  )

  const userInfo = useSelector(getUserInfo)
  const cemFeatures = useSelector(getCemFeatures)
  const isInvoiceUser = isCemFeatureEnabled(
    [
      CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
      CEM_FEATURE_PAYMENT_PAY_INVOICES,
    ],
    cemFeatures,
    'any',
  )
  useTracking(userInfo, dateConsentChanged, isInvoiceUser)

  // Only load the OneTrust script when the user is logged in to prevent the banner from flashing before the login page is shown.
  useEffect(() => {
    if (userInfo?.userId) {
      window.addEventListener('OneTrustGroupsUpdated', () => {
        setDateConsentChanged(new Date())
      })

      const addOneTrustScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`
        script.setAttribute('data-language', 'en')
        script.setAttribute(
          'data-domain-script',
          process.env.REACT_APP_ONETRUST_DOMAIN_SCRIPT || '',
        )
        document.head.appendChild(script)
      }
      addOneTrustScript()
    }
  }, [userInfo])

  return null
}

export default TrackingManager
