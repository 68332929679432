import { useTabContext } from '@mui/lab/TabContext'
import { Link as MUILink, ListItem } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import { AnalyticEvent } from '../../../analytics/adobe/types'

const styles: Styles = {
  listItem: {
    padding: 0,
  },
  link: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    '&[aria-current="true"]': {
      fontFamily: tokens.type.fontFamilies.preloBold,
    },
  }),
  button: (theme) => ({
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingX: '0.75rem',
    height: '100%',
    fontFamily: tokens.type.fontFamilies.preloBook,
    fontSize: '1rem',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  }),
}

const analyticEventType: AnalyticEvent = 'Navigation_Click'

interface RouterLinkProps {
  children: React.ReactNode
  tabIndex?: number
  testId: string
  isCurrent?: boolean
  to: string
  value?: string
}

const RouterNavListItem = ({
  children,
  isCurrent,
  tabIndex,
  testId,
  to,
  value,
}: RouterLinkProps) => {
  const tabContext = useTabContext()
  const isCurrentItem = isCurrent ?? value === tabContext?.value

  return (
    <ListItem sx={styles.listItem}>
      <MUILink
        component={RouterLink}
        sx={styles.link}
        to={to}
        aria-current={isCurrentItem ?? 'page'}
        tabIndex={tabIndex}
        data-testid={testId}
        data-analytics-id={testId}
        data-analytic-event={analyticEventType}>
        {children}
      </MUILink>
    </ListItem>
  )
}

export default RouterNavListItem
