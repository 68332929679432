import { FeatureFlag, TargetingFeatureFlag } from '@rsmus/ecp-core-constants'
import { CemFeatureModel, EntityLevelEnum } from '@rsmus/ecp-userservice'
import { FEATURE_MANAGEMENT } from '../../envVariables'

export const isTargetedFeatureEnabled = (
  feature: TargetingFeatureFlag | TargetingFeatureFlag[],
  enabledFeatures: TargetingFeatureFlag[],
  matchType: 'all' | 'any' = 'all',
): boolean => {
  if (enabledFeatures === undefined) {
    return false
  }
  const features = !Array.isArray(feature) ? [feature] : feature
  return (
    features?.length > 0 &&
    (matchType === 'all'
      ? features.every((f) => enabledFeatures?.includes(f))
      : features.some((f) => enabledFeatures?.includes(f)))
  )
}

export const isCemFeatureEnabled = (
  feature: string | string[],
  enabledFeatures: CemFeatureModel[],
  matchType: 'all' | 'any',
  entityLevel: EntityLevelEnum = EntityLevelEnum.Any,
  entityId: string | null = null,
): boolean => {
  if (enabledFeatures === undefined) {
    return false
  }
  const features = !Array.isArray(feature) ? [feature] : feature
  const checkFeature = (f: string) =>
    enabledFeatures.some(
      (ef) =>
        ef.featureName === f &&
        ef.entities?.some(
          (e) =>
            (entityLevel === EntityLevelEnum.Any ||
              e.entityLevel === entityLevel) &&
            (entityId === null || e.entityId === entityId),
        ),
    )
  return (
    features?.length > 0 &&
    (matchType === 'all'
      ? features.every(checkFeature)
      : features.some(checkFeature))
  )
}

export const isFeatureFlagEnabled = (
  feature: FeatureFlag | FeatureFlag[],
  matchType: 'all' | 'any' = 'all',
): boolean => {
  if (undefined === FEATURE_MANAGEMENT) {
    return false
  }
  const features = !Array.isArray(feature) ? [feature] : feature
  const availableFeatures =
    typeof FEATURE_MANAGEMENT === 'string'
      ? JSON.parse(FEATURE_MANAGEMENT)
      : FEATURE_MANAGEMENT
  const allowedFeatures = Object.keys(availableFeatures).filter(
    (key) => availableFeatures[key] === true,
  )
  return (
    features?.length > 0 &&
    (matchType === 'all'
      ? features.every((f) => allowedFeatures?.includes(f))
      : features.some((f) => allowedFeatures?.includes(f)))
  )
}
