import { useContext, useEffect, useState } from 'react'
import { getAccessToken, OidcIdentityContext } from '@rsmus/react-auth'

const interceptorInstance = {
  logoutIfExpired: async () => false,
}

const Interceptor = ({ children }: { children: JSX.Element }) => {
  const { logout } = useContext(OidcIdentityContext)
  const [isSet, setIsSet] = useState(false)

  // Decodes the JWT token so we can check if it's expired.
  const decodeToken = (token: string) => {
    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join(''),
      )

      return JSON.parse(jsonPayload)
    } catch (e) {
      return {}
    }
  }

  useEffect(() => {
    interceptorInstance.logoutIfExpired = async () => {
      const token = await getAccessToken()

      // If the token is missing, log the user out
      if (!token) {
        if (logout) {
          logout(true)
          return true
        }
      }

      // Decode the token and check if it's expired
      // If it is, log the user out
      const decodedToken = decodeToken(token ?? '')
      if (decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
        if (logout) {
          logout(true)
          return true
        }
      }

      return false
    }

    setIsSet(true)

    return () => {
      interceptorInstance.logoutIfExpired = async () => false
    }
  }, [])

  return isSet ? children : null
}

export default interceptorInstance
export { Interceptor }
