import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '..'

// Define a type for the slice state
interface FaqsState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  faqs: any[]
  expandAll: boolean
  hasFocus?: string
}

// Define the initial state using that type
const initialState: FaqsState = {
  faqs: [],
  expandAll: false,
}

export const faqsSlice = createSlice({
  name: 'faqs',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setfaqData: (state, { payload }) => {
      state.faqs = payload
    },
    setExpandAll: (state, { payload }) => {
      state.expandAll = payload
    },
  },
})

export const { setfaqData, setExpandAll } = faqsSlice.actions
export const getFaqs = (state: RootState) => state.faqs.faqs
export const getExpandAll = (state: RootState) => state.faqs.expandAll

export default faqsSlice.reducer
