import React, { useCallback, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDebounce } from 'usehooks-ts'
import CurrencyRangeSlider from '../../../rsmCoreComponents/CurrencyRangeSlider/CurrencyRangeSlider'
import InvoicesFilterCategoryContainer from './InvoicesFilterCategoryContainer'
import {
  getInvoiceSelectedFilters,
  setFilterValue,
  getAmountRangeFilterMax,
} from '../../../store/invoices/invoiceSelectedFiltersSlice'

interface InvoicesAmountRangeFilterProps {
  filterCategory: string
  clearCategoryFilter: (category: string) => void
  changeFilterAndSearch: (filter: string) => void
}

const InvoicesAmountRangeFilter = ({
  filterCategory,
  clearCategoryFilter,
  changeFilterAndSearch,
}: InvoicesAmountRangeFilterProps) => {
  const selectedFilters = useSelector(getInvoiceSelectedFilters)
  const amountRangeFilterMax = useSelector(getAmountRangeFilterMax)
  const [currentValues, setCurrentValues] = useState<number[]>([
    selectedFilters.invoiceAmountFrom ?? 0,
    selectedFilters.invoiceAmountTo ?? 0,
  ])
  const [debounceValue, setDebounceValue] = useState<number[]>([
    selectedFilters.invoiceAmountFrom ?? 0,
    selectedFilters.invoiceAmountTo ?? 0,
  ])
  const debounce = useDebounce(debounceValue, 500)

  useEffect(() => {
    if (debounce) {
      changeFilterAndSearch(
        setFilterValue(filterCategory, debounceValue.join('**')),
      )
    }
  }, [debounce])

  useEffect(() => {
    // will trigger when store values are cleared
    if (
      !selectedFilters.invoiceAmountFrom &&
      !selectedFilters.invoiceAmountTo
    ) {
      setCurrentValues([0, 0])
    }
  }, [
    selectedFilters.invoiceAmountFrom,
    selectedFilters.invoiceAmountTo,
    setCurrentValues,
  ])

  const handleChange = useCallback(
    (_: Event, value: number | number[]) => {
      const newValues = value as number[]
      setCurrentValues(newValues)
      setDebounceValue(newValues)
    },
    [filterCategory],
  )

  return (
    <InvoicesFilterCategoryContainer
      filterCategory={filterCategory}
      clearCategoryFilter={clearCategoryFilter}>
      <CurrencyRangeSlider
        minMaxRange={[0, amountRangeFilterMax]}
        currentValues={currentValues}
        currency={selectedFilters.currency}
        handleChange={handleChange}
      />
    </InvoicesFilterCategoryContainer>
  )
}

export default InvoicesAmountRangeFilter
