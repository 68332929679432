import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { Styles } from '../../../types'
import { tokens } from '../../../styles/materialTheme'

interface HtmlViewerProps {
  title: string
  html: string
  onScrolledDown: () => void
}

const styles: Styles = {
  content: {
    marginTop: '0.6rem',
    height: '100%',
    maxHeight: 'min(70.2rem, 100%)',
    minHeight: '3rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: tokens.colors.rsmGray.dividers,
    '> iframe': {
      width: '100%',
      height: '100%',
      border: 'none',
      overflowY: 'scroll',
    },
  },
}

const HtmlViewer = ({ title, html, onScrolledDown }: HtmlViewerProps) => {
  const [isRead, setIsRead] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (!html) return () => {}
    setIsRead(false)

    const isAtBottom = (iframeDocument: Document) =>
      (iframeDocument.scrollingElement?.scrollHeight || 0) -
        (iframeDocument.scrollingElement?.scrollTop || 0) <=
      (iframeDocument.scrollingElement?.clientHeight || 0) + 30

    let timeoutHandle: number

    const scrollCheckFallbackForMobile = (iframeDocument: Document) => {
      if (isRead) return
      if (isAtBottom(iframeDocument)) {
        setIsRead(true)
        onScrolledDown()
      } else {
        timeoutHandle = window.setTimeout(() => {
          scrollCheckFallbackForMobile(iframeDocument)
        }, 100)
      }
    }

    if (iframeRef.current) {
      const iframeDocument =
        iframeRef.current.contentWindow?.document ||
        iframeRef.current.contentDocument
      if (iframeDocument) {
        iframeDocument.open()
        iframeDocument.write(html)
        iframeDocument.close()
        iframeDocument.onscroll = () => {
          if (isAtBottom(iframeDocument)) {
            setIsRead(true)
            onScrolledDown()
          }
        }
        scrollCheckFallbackForMobile(iframeDocument)
      }
    }

    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle)
      }
    }
  }, [html, onScrolledDown])

  return (
    <Box sx={styles.content}>
      <iframe ref={iframeRef} sandbox="allow-same-origin" title={title} />
    </Box>
  )
}

export default HtmlViewer
