import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'
import {
  FileResponse,
  ScheduledInvoiceViewModel,
} from '@rsmus/ecp-financeservice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import {
  getPayableAmount,
  getInvoicePayments,
  ScheduledInstallments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from '../Invoices/InvoicesHeader'
import api from '../../../api'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
  INVOICE_FILTER_STATUS_CANCELLED,
  INVOICE_FILTER_STATUS_PAID,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import {
  getPaidInvoices,
  getPaymentMethodDisplay,
  handleNavigateBackToInvoices,
} from './PaymentSuccess.service'
import ScheduledPaymentSuccessTable from './ScheduledPaymentSuccessTable'
import CancelScheduleInvoicesDialog from '../Invoices/CancelScheduleInvoicesDialog'
import ScheduledPaymentDateChangeDialog from '../AccountManagement/ScheduledPaymentDateChangeDialog'
import {
  PaymentSelectedFiltersConstants,
  clearAllFilters,
  setFilterValue,
  updateFilterValue,
} from '../../../store/invoices/paymentSelectedFiltersSlice'

interface ScheduledPaymentSuccessProps {
  scheduledInstallments: ScheduledInstallments
}

const IsScheduledPayment = true

const styles: Styles = {
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    marginBottom: '3.125rem',
  }),
  toggle: (theme) => ({
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginRight: '0.5rem',
  }),
  detailContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '4rem',
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '4rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: () => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: (theme) => ({
    fontWeight: 'bold',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '0.875rem',
      minWidth: '15rem',
    },
    [theme.breakpoints.only('mobile')]: {
      fontSize: '0.875rem',
      minWidth: '10rem',
    },
  }),
  detailLabelContent: (theme) => ({
    [theme.breakpoints.down('tablet')]: {
      minWidth: '2rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
  paymenthistoryLink: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      justifyContent: 'start',
      verticalAlign: 'top',
      padding: 0,
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: 0,
    },
  }),
  paymenthistorylinkButton: {
    display: 'block',
    textDecoration: 'underline',
  },
  ButtonDownload: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.down('tablet')]: {
      display: 'block',
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: '15rem',
      top: '15rem',
      left: '6rem',
      position: 'unset',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  ButtonEdit: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.down('tablet')]: {
      display: 'block',
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      top: '15rem',
      left: '6rem',
      position: 'unset',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  InvoicesDetails: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: 'auto',
      textAlign: 'start',
    },
  }),
}

const ScheduledPaymentSuccess = ({
  scheduledInstallments,
}: ScheduledPaymentSuccessProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const invoicePayments = useSelector(getInvoicePayments)
  const payableAmount = useSelector(getPayableAmount)
  const navigate = useNavigate()
  const [invoiceViewState, setInvoiceViewState] = useState(false)
  const { isDesktop } = useDeviceType()
  const { isMobile } = useDeviceType()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [openChangeDateDialog, setOpenChangeDateDialog] = useState(false)
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoiceViewModel[]
  >([])
  const [nextPaymentDate, setNextPaymentDate] = useState<Date | undefined>(
    undefined,
  )

  const fetchPlanDetails = useCallback(async (planId: number) => {
    if (planId) {
      try {
        const { data } = await api.finance.payment_GetPlanDetails(planId)
        setNextPaymentDate(data?.nextPaymentDate)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch plan details', error)
      }
    }
  }, [])

  const handleBackToInvoices = () => {
    handleNavigateBackToInvoices(dispatch, navigate)
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (invoicePayments.length === 0) {
      navigate('/invoicing')
    }
  }, [])

  const handleTogglePaidInvoices = () => {
    setInvoiceViewState(!invoiceViewState)
  }

  const handleDownloadReceipt = useCallback(async () => {
    // Call API for Scheduled payment invoice
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadScheduledPaymentReceipt(
        scheduledInstallments?.installments?.[0]?.transactionId || '',
      )
    downloadFileStream(response)
  }, [
    api.finance.invoiceDocument_DownloadScheduledPaymentReceipt,
    downloadFileStream,
    scheduledInstallments,
  ])

  const payScheduledSuccessDescription = (
    <Box>
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription1')}{' '}
      <Button
        onClick={() => navigate('/invoicing/payments')}
        sx={styles.paymenthistoryLink}
        role="link">
        <Box component="span" sx={styles.paymenthistorylinkButton}>
          {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription2')}{' '}
        </Box>
      </Button>{' '}
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription3')}
    </Box>
  )

  const handleCancelScheduledPayment = () => {
    const invoiceData: ScheduledInvoiceViewModel[] = []
    invoicePayments.forEach((invoice) => {
      invoiceData.push({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        clientName: invoice.customerName,
        openAmount: invoice.openAmount,
      } as ScheduledInvoiceViewModel)
    })
    setScheduledInvoicesData(invoiceData)
    setIsCancelDialogOpen(true)
  }

  const getButtonSlot = (): JSX.Element | undefined =>
    isCemFeatureEnabled(
      [
        CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
        CEM_FEATURE_PAYMENT_PAY_INVOICES,
      ],
      cemFeatures,
      'any',
    ) ? (
      <>
        <Button
          sx={styles.ButtonDownload}
          size="small"
          aria-label={t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
          variant="outlined"
          onClick={handleDownloadReceipt}>
          {t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
        </Button>
        <Button
          sx={styles.ButtonEdit}
          size="small"
          aria-label={t('Invoicing.PaymentSuccessPage.ChangePaymentDate')}
          variant="outlined"
          onClick={() => setOpenChangeDateDialog(true)}>
          {t('Invoicing.PaymentSuccessPage.ChangePaymentDate')}
        </Button>
        <Button
          sx={styles.ButtonEdit}
          size="small"
          aria-label={t('Invoicing.PaymentSuccessPage.CancelScheduledPayment')}
          variant="outlined"
          onClick={handleCancelScheduledPayment}>
          {t('Invoicing.PaymentSuccessPage.CancelScheduledPayment')}
        </Button>
      </>
    ) : undefined

  const handleConfirmCancelDialog = () => {
    dispatch(clearAllFilters())
    // paid status needs to get added first so when payment transaction page loads,
    // it removes the default paid status leaving only the cancelled status selected
    dispatch(
      updateFilterValue(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentStatus,
          INVOICE_FILTER_STATUS_PAID,
        ),
      ),
    )
    dispatch(
      updateFilterValue(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentStatus,
          INVOICE_FILTER_STATUS_CANCELLED,
        ),
      ),
    )
    setIsCancelDialogOpen(false)
    navigate('/invoicing/payments')
  }

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t('Invoicing.PaymentSuccessPage.PaymentScheduled')}
        primaryHeaderDescription={payScheduledSuccessDescription}
        buttonSlot={getButtonSlot()}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaidAmount"
        amountHeaderText={t('Invoicing.AmountScheduled')}
        amount={payableAmount}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.DateScheduled')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {t('Invoicing.PaymentSuccessPage.TransactionDateFormat', {
                    date:
                      nextPaymentDate ??
                      scheduledInstallments?.installments?.[0]?.scheduledDate,
                  })}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PaymentMethod')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaymentMethodDisplay(
                    scheduledInstallments?.paymentMethod,
                    scheduledInstallments?.accountNumber,
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PayerName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {scheduledInstallments?.payer}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.Invoices')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaidInvoices(invoicePayments).map((invoice) => (
                    <Box key={invoice.customerName} sx={styles.InvoicesDetails}>
                      {!isMobile && <Box>{invoice.customerName}</Box>}
                      <Box>{invoice.invoices}</Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
          {!isMobile && (
            <Button
              data-testid="Btn_Invoicing_ViewPaidInvoices"
              onClick={handleTogglePaidInvoices}
              aria-atomic
              aria-expanded={invoiceViewState}
              aria-controls="invoicesPaymentTable"
              aria-live="polite"
              sx={styles.textButton}>
              <Box component="span" sx={styles.toggle}>
                {invoiceViewState
                  ? t(
                      'Invoicing.PaymentSuccessPage.HideScheduledPaidInvoiceDetails',
                    )
                  : t(
                      'Invoicing.PaymentSuccessPage.ViewScheduledPaidInvoiceDetails',
                    )}
              </Box>
              <FontAwesomeIcon
                icon={invoiceViewState ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </Button>
          )}
        </Box>

        {invoiceViewState && !isMobile && (
          <ScheduledPaymentSuccessTable invoicePayments={invoicePayments} />
        )}
      </Box>
      <CancelScheduleInvoicesDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onConfirm={() => handleConfirmCancelDialog()}
        scheduledInvoicesData={scheduledInvoicesData}
        selectedPlanId={scheduledInstallments?.installmentPlanId ?? 0}
        isScheduled={IsScheduledPayment}
        planDate={format(new Date(), 'MM/dd/yyyy')}
      />
      <ScheduledPaymentDateChangeDialog
        open={openChangeDateDialog}
        onClose={() => {
          setOpenChangeDateDialog(false)
          fetchPlanDetails(scheduledInstallments?.installmentPlanId ?? 0)
        }}
        paymentInstallmentPlanId={scheduledInstallments?.installmentPlanId ?? 0}
        planName={
          t('Invoicing.PaymentSuccessPage.ScheduledPaymentDateSelect', {
            name: format(new Date(), 'MM/dd/yyyy'),
          }) ?? ''
        }
        existingPaymentDate={
          scheduledInstallments?.installments?.[0].scheduledDate ?? new Date()
        }
      />
    </>
  )
}

export default ScheduledPaymentSuccess
