import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer'
import type { RootState } from '..'
import { RsmPaginationPageSizes } from '../../rsmCoreComponents/components/RsmPagination/RsmPagination'

// These constants are KEYS and should match with
// InvoiceSelectedFiltersState for key here and server data for values returned by invoicefilters
// DO NOT change values here unless warranted.
export const InvoiceSelectedFiltersConstants = {
  Seperator: '##',
  DefaultCurrency: 'usd',
  searchString: 'search',
  invoiceStatus: 'status',
  invoiceDates: 'daterange',
  invoiceStartDate: 'invoiceStartDate',
  invoiceEndDate: 'invoiceEndDate',
  invoiceDate: 'date',
  invoiceAmounts: 'amountrange',
  invoiceAmountFrom: 'invoiceAmountFrom',
  invoiceAmountTo: 'invoiceAmountTo',
  currency: 'currency',
  invoiceIds: 'invoiceids',
  SortBy: 'date', // New constant for sorting
  isSortAscending: false, // New constant for sorting
  clientId: 'clientId',
}

export const setFilterValue = (
  category: string,
  filter: string | Date | null,
) => {
  // isNaN and Number.isNaN have different behavior, so need to disable the eslint rule.
  if (
    filter instanceof Date &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(filter as Date as unknown as number) &&
    filter !== null
  ) {
    const utcDate = new Date(
      Date.UTC(filter.getFullYear(), filter.getMonth(), filter.getDate()),
    )
    return `${category}${
      InvoiceSelectedFiltersConstants.Seperator
    }${utcDate.toISOString()}`
  }
  return `${category}${InvoiceSelectedFiltersConstants.Seperator}${filter}`
}

export const amountRangeFilterMax = 1000000

const setError = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  errorMessage: string,
) => {
  state.filterStateValidation = {
    isValid: false,
    errorMessages: [...state.filterStateValidation.errorMessages, errorMessage],
  }
}

const clearError = (state: WritableDraft<InvoiceSelectedFiltersState>) => {
  state.filterStateValidation = {
    isValid: true,
    errorMessages: [],
  }
}

const changeRowsPerPage = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  pageSize: RsmPaginationPageSizes,
) => {
  if (pageSize <= 0) setError(state, 'pagesize cannot be negative')
  else state.pageSize = pageSize
}

const changePageNumber = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  pageNumber: number,
) => {
  if (pageNumber <= 0) setError(state, 'pagenumber cannot be negative')
  else state.pageNumber = pageNumber
}

const changeSorting = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  sortBy: string,
  isAscending: boolean,
) => {
  state.sortby = sortBy
  state.isSortAscending = isAscending
}

const clearCategoryFilter = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  category: string,
) => {
  clearError(state)
  if (!category) {
    setError(state, 'category required for clear category')
    return
  }
  switch (category) {
    case InvoiceSelectedFiltersConstants.invoiceStatus:
      state.invoiceStatus = []
      break

    case InvoiceSelectedFiltersConstants.invoiceDates:
      state.invoiceStartDate = null
      state.invoiceEndDate = null
      state.invoiceDateRange = ''
      clearError(state)
      break

    case InvoiceSelectedFiltersConstants.invoiceAmounts:
      state.invoiceAmountFrom = undefined
      state.invoiceAmountTo = undefined
      clearError(state)
      break

    case InvoiceSelectedFiltersConstants.currency:
      state.currency = InvoiceSelectedFiltersConstants.DefaultCurrency
      break
    case InvoiceSelectedFiltersConstants.clientId:
      state.clientId = undefined
      clearError(state)
      break

    default:
      setError(
        state,
        'invalid category in filter, should be defined in constants locally',
      )
  }

  state.pageNumber = 1
}

const changeFilterValue = (
  state: WritableDraft<InvoiceSelectedFiltersState>,
  filter: string,
) => {
  clearError(state)
  const [category, value] = filter?.split(
    InvoiceSelectedFiltersConstants.Seperator,
  ) ?? [undefined, undefined]
  if (!category || !value) {
    setError(state, 'filter category and value not combined properly')
    return
  }
  let idx = -1
  switch (category) {
    case InvoiceSelectedFiltersConstants.invoiceStatus:
      idx = state.invoiceStatus.indexOf(value)
      if (idx >= 0) state.invoiceStatus.splice(idx, 1)
      else state.invoiceStatus.push(value)
      break

    case InvoiceSelectedFiltersConstants.invoiceStartDate:
      if (value === 'null') {
        state.invoiceStartDate = null
        state.invoiceDateRange = ''
      } else {
        state.invoiceStartDate = new Date(value)
      }
      break

    case InvoiceSelectedFiltersConstants.invoiceEndDate:
      if (value === 'null') {
        state.invoiceEndDate = null
        state.invoiceDateRange = ''
      } else {
        state.invoiceEndDate = new Date(value)
      }
      break

    case InvoiceSelectedFiltersConstants.invoiceDates:
      state.invoiceDateRange = value
      break

    case InvoiceSelectedFiltersConstants.invoiceAmounts:
      {
        const [start, end] = value.split('**')
        state.invoiceAmountFrom = Number(start)
        state.invoiceAmountTo = Number(end)
      }
      break

    case InvoiceSelectedFiltersConstants.currency:
      state.currency = value
      break
    case InvoiceSelectedFiltersConstants.clientId:
      state.clientId = value
      break

    default:
      setError(
        state,
        'invalid category in filter, should be defined in constants locally',
      )
  }

  state.pageNumber = 1
}

export interface FilterStateValidation {
  isValid: boolean
  errorMessages: string[]
}

// ALL Filter data for Invoice table go here
export interface InvoiceSelectedFiltersState {
  pageNumber: number
  pageSize: RsmPaginationPageSizes
  searchString: string | undefined
  invoiceStatus: string[]
  invoiceStartDate: Date | null
  invoiceEndDate: Date | null
  invoiceDateRange: string
  invoiceAmountFrom: number | undefined
  invoiceAmountTo: number | undefined
  currency: string
  invoiceIds: string[]
  filterStateValidation: FilterStateValidation
  sortby: string | undefined // Add SortBy property
  isSortAscending: boolean | true // Add isSortAscending property
  clientId: string | undefined
}

// Define the initial state
const initialState: InvoiceSelectedFiltersState = {
  pageNumber: 1,
  pageSize: 10,
  searchString: '',
  invoiceStatus: [],
  invoiceStartDate: null,
  invoiceEndDate: null,
  invoiceDateRange: '',
  invoiceAmountFrom: 0,
  invoiceAmountTo: 0,
  currency: InvoiceSelectedFiltersConstants.DefaultCurrency,
  invoiceIds: [],
  sortby: 'InvoiceDate',
  isSortAscending: false,
  filterStateValidation: {
    isValid: true,
    errorMessages: [],
  },
  clientId: undefined,
}

export const invoiceSelectedFiltersSlice = createSlice({
  name: 'invoiceSelectedFilters',
  initialState,
  reducers: {
    clearAllFilters: (): InvoiceSelectedFiltersState => initialState,
    clearCategoryFilters(state, { payload }: PayloadAction<string>) {
      clearCategoryFilter(state, payload)
    },
    setPageSize(state, { payload }: PayloadAction<RsmPaginationPageSizes>) {
      changeRowsPerPage(state, payload)
    },
    toPageNumber(state, { payload }: PayloadAction<number>) {
      changePageNumber(state, payload)
    },
    toNextPage(state) {
      changePageNumber(state, state.pageNumber + 1)
    },
    toPreviousPage(state) {
      changePageNumber(state, state.pageNumber - 1)
    },
    updateFilterValue(state, { payload }: PayloadAction<string>) {
      changeFilterValue(state, payload)
    },
    updateSearch(state, { payload }: PayloadAction<string>) {
      state.searchString = payload
      state.pageNumber = 1
    },
    updateSorting(
      state,
      { payload }: PayloadAction<{ sortBy: string; isAscending: boolean }>,
    ) {
      changeSorting(state, payload.sortBy, payload.isAscending)
    },
  },
})

export const {
  clearAllFilters,
  clearCategoryFilters,
  setPageSize,
  toPageNumber,
  toNextPage,
  toPreviousPage,
  updateFilterValue,
  updateSearch,
  updateSorting,
} = invoiceSelectedFiltersSlice.actions

export const getPageInfo = (state: RootState) => ({
  pageNumber: state.invoiceSelectedFilters.pageNumber,
  pageSize: state.invoiceSelectedFilters.pageSize,
})

export const getInvoiceSelectedFilters = (state: RootState) =>
  state.invoiceSelectedFilters

export const getAmountRangeFilterMax = (): number => amountRangeFilterMax
// retrieval of max amount range value from central location to be used in InvoicesChips.tsx and InvoicesAmountRangeFilter.tsx
// setting max to 1000000 due to steps in currency range slider dividing by 100

export default invoiceSelectedFiltersSlice.reducer
