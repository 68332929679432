import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Tooltip } from '@mui/material'
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons'
import {
  FileResponse,
  ScheduledInvoiceViewModel,
} from '@rsmus/ecp-financeservice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import tokens from '../../../styles/tokens.json'
import { Styles } from '../../../types'
import {
  getPayableAmount,
  getInvoicePayments,
  ScheduledInstallments,
} from '../../../store/invoices/invoiceSelectedInvoicesSlice'
import { formatCurrency } from '../../../rsmCoreComponents/utils/formatters'
import { useDeviceType } from '../../../rsmCoreComponents/hooks/useDeviceType'
import InvoicesHeader from '../Invoices/InvoicesHeader'
import api from '../../../api'
import { isCemFeatureEnabled } from '../../../rsmCoreComponents/utils/featureFlagUtils'
import downloadFileStream from '../../../rsmCoreComponents/utils/fileStreamUtils'
import {
  CEM_FEATURE_PAYMENT_PAY_INVOICES,
  CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
  INVOICE_FILTER_STATUS_CANCELLED,
  INVOICE_FILTER_STATUS_PAID,
} from '../../../utils/constants/constants'
import { getCemFeatures } from '../../../store/userInfo/userInfoSlice'
import ScheduledPaymentSuccessTable from './ScheduledPaymentSuccessTable'
import {
  getPaidInvoices,
  getPaymentMethodDisplay,
  handleNavigateBackToInvoices,
} from './PaymentSuccess.service'
import CancelScheduleInvoicesDialog from '../Invoices/CancelScheduleInvoicesDialog'
import ScheduledPaymentDateChangeDialog from '../AccountManagement/ScheduledPaymentDateChangeDialog'
import {
  PaymentSelectedFiltersConstants,
  clearAllFilters,
  setFilterValue,
  updateFilterValue,
} from '../../../store/invoices/paymentSelectedFiltersSlice'

interface ScheduledPaymentSuccessProps {
  scheduledInstallments: ScheduledInstallments
}

const IsScheduledPayment = false

const styles: Styles = {
  textButton: (theme) => ({
    color: theme.palette.secondary.main,
    marginBottom: '3.125rem',
  }),
  toggle: (theme) => ({
    display: 'block',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    marginRight: '0.5rem',
  }),
  detailContainer: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      paddingTop: '4rem',
      marginLeft: '6.5rem',
      marginRight: '6.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      paddingTop: '4rem',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    [theme.breakpoints.only('mobile')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  }),
  detailSection: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
    },
    [theme.breakpoints.only('tablet')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
    },
    [theme.breakpoints.only('mobile')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'start',
    },
  }),
  details: () => ({
    flex: '1 1 auto',
  }),
  detailRow: () => ({
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'start',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  }),
  detailLabel: (theme) => ({
    [theme.breakpoints.only('desktop')]: {
      minWidth: '15rem',
      fontWeight: 'bold',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '15rem',
      fontWeight: 'bold',
      fontSize: '0.875rem',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '7rem',
      fontWeight: 'bold',
      fontSize: '0.875rem',
    },
  }),
  detailLabelContent: (theme) => ({
    [theme.breakpoints.only('mobile')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
    },
  }),
  detailLabelContent2: (theme) => ({
    display: 'grid',
    [theme.breakpoints.only('desktop')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
      gridTemplateColumns: 'auto auto auto',
    },
    [theme.breakpoints.only('mobile')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
      gridTemplateColumns: 'auto',
    },
    [theme.breakpoints.only('tablet')]: {
      minWidth: '3rem',
      fontSize: '0.875rem',
      paddingLeft: '0.5rem',
      gridTemplateColumns: 'auto auto auto',
    },
  }),
  autopay: () => ({
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20rem',
    height: '10rem',
  }),
  ButtonDownload: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.down('tablet')]: {
      display: 'block',
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      width: '14rem',
      top: '16rem',
      left: '4rem',
      position: 'unset',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  ButtonEdit: (theme) => ({
    marginRight: '0.5rem',
    [theme.breakpoints.down('tablet')]: {
      display: 'block',
      marginBottom: '0.5rem',
    },
    [theme.breakpoints.only('tablet')]: {
      top: '16rem',
      left: '4rem',
      position: 'unset',
    },
    [theme.breakpoints.only('mobile')]: {
      width: '100%',
    },
  }),
  amountSubText: () => ({
    fontSize: '0.75rem',
  }),
  paymenthistoryLink: (theme) => ({
    [theme.breakpoints.up('tablet')]: {
      color: theme.palette.secondary.main,
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      justifyContent: 'start',
      verticalAlign: 'top',
      padding: 0,
      marginBottom: '1rem',
    },
    [theme.breakpoints.only('mobile')]: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: 0,
    },
  }),
  paymenthistorylinkButton: {
    display: 'block',
    textDecoration: 'underline',
  },
  InvoicesDetails: (theme) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    [theme.breakpoints.only('mobile')]: {
      gridTemplateColumns: 'auto',
      textAlign: 'start',
    },
  }),
}

const InstallmentPaymentSuccess = ({
  scheduledInstallments,
}: ScheduledPaymentSuccessProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cemFeatures = useSelector(getCemFeatures)
  const invoicePayments = useSelector(getInvoicePayments)
  const payableAmount = useSelector(getPayableAmount)
  const navigate = useNavigate()
  const [invoiceViewState, setInvoiceViewState] = useState(false)
  const { isDesktop } = useDeviceType()
  const { isMobile } = useDeviceType()

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false)
  const [openChangeDateDialog, setOpenChangeDateDialog] = useState(false)
  const [scheduledInvoicesData, setScheduledInvoicesData] = useState<
    ScheduledInvoiceViewModel[]
  >([])
  const [nextPaymentDate, setNextPaymentDate] = useState<Date | undefined>(
    undefined,
  )

  const fetchPlanDetails = useCallback(async (planId: number) => {
    if (planId) {
      try {
        const { data } = await api.finance.payment_GetPlanDetails(planId)
        setNextPaymentDate(data?.nextPaymentDate)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch plan details', error)
      }
    }
  }, [])

  const handleBackToInvoices = () => {
    handleNavigateBackToInvoices(dispatch, navigate)
  }

  useEffect(() => {
    // Prevent user from accessing this page directly.
    if (invoicePayments.length === 0) {
      navigate('/invoicing')
    }
  }, [])

  const handleTogglePaidInvoices = () => {
    setInvoiceViewState(!invoiceViewState)
  }

  const handleDownloadReceipt = useCallback(async () => {
    const transactionIdsString =
      scheduledInstallments?.installments
        ?.map((installment) => installment.transactionId)
        .join(',') || ''
    const response: FileResponse =
      await api.finance.invoiceDocument_DownloadInstallmentsPaymentReceipt(
        transactionIdsString,
      )
    downloadFileStream(response)
  }, [
    api.finance.invoiceDocument_DownloadInstallmentsPaymentReceipt,
    downloadFileStream,
    scheduledInstallments,
  ])

  const handleCancelInstallmentPayment = () => {
    const invoiceData: ScheduledInvoiceViewModel[] = []
    invoicePayments.forEach((invoice) => {
      invoiceData.push({
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
        clientName: invoice.customerName,
        openAmount: invoice.openAmount,
      } as ScheduledInvoiceViewModel)
    })
    setScheduledInvoicesData(invoiceData)
    setIsCancelDialogOpen(true)
  }

  const getButtonSlot = (): JSX.Element | undefined =>
    isCemFeatureEnabled(
      [
        CEM_FEATURE_PAYMENT_VIEW_INVOICES_AND_DOCUMENTS,
        CEM_FEATURE_PAYMENT_PAY_INVOICES,
      ],
      cemFeatures,
      'any',
    ) ? (
      <>
        <Button
          sx={styles.ButtonDownload}
          size="small"
          aria-label={t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
          variant="outlined"
          onClick={handleDownloadReceipt}>
          {t('Invoicing.PaymentSuccessPage.DownloadReceipt')}
        </Button>
        <Button
          sx={styles.ButtonEdit}
          size="small"
          aria-label={t('Invoicing.PaymentSuccessPage.ChangePaymentDate')}
          variant="outlined"
          onClick={() => setOpenChangeDateDialog(true)}>
          {t('Invoicing.PaymentSuccessPage.ChangePaymentDate')}
        </Button>
        <Button
          sx={styles.ButtonEdit}
          size="small"
          aria-label={t(
            'Invoicing.PaymentSuccessPage.CancelInstallmentPayment',
          )}
          variant="outlined"
          onClick={handleCancelInstallmentPayment}>
          {t('Invoicing.PaymentSuccessPage.CancelInstallmentPayment')}
        </Button>
      </>
    ) : undefined

  const payInstallmentsSuccessDescription = (
    <Box>
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription1')}{' '}
      <Button
        onClick={() => navigate('/invoicing/payments')}
        sx={styles.paymenthistoryLink}
        role="link">
        <Box component="span" sx={styles.paymenthistorylinkButton}>
          {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription2')}{' '}
        </Box>
      </Button>{' '}
      {t('Invoicing.PaymentSuccessPage.PaymentSucessDescription3')}
    </Box>
  )

  const handleConfirmCancelDialog = () => {
    dispatch(clearAllFilters())
    // paid status needs to get added first so when payment transaction page loads,
    // it removes the default paid status leaving only the cancelled status selected
    dispatch(
      updateFilterValue(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentStatus,
          INVOICE_FILTER_STATUS_PAID,
        ),
      ),
    )
    dispatch(
      updateFilterValue(
        setFilterValue(
          PaymentSelectedFiltersConstants.paymentStatus,
          INVOICE_FILTER_STATUS_CANCELLED,
        ),
      ),
    )
    setIsCancelDialogOpen(false)
    navigate('/invoicing/payments')
  }

  return (
    <>
      <InvoicesHeader
        primaryHeaderId="PaySelected"
        primaryHeaderText={t(
          'Invoicing.PaymentSuccessPage.InstallmentPaymentDetails',
        )}
        primaryHeaderDescription={payInstallmentsSuccessDescription}
        buttonSlot={getButtonSlot()}
        backButtonId="BackToInvoices"
        backButtonText={t('Invoicing.BackToInvoices')}
        handleNavigation={handleBackToInvoices}
        amountHeaderId="PaidAmount"
        amountHeaderText={t('Invoicing.TotalInstallmentAmount')}
        amountSubSlot={
          <Tooltip title={`${t('Invoicing.TotalInstallmentAmountToolTip')}`}>
            <Box sx={styles.amountSubText}>
              {(scheduledInstallments?.installments?.length || 0) >= 1
                ? t('Invoicing.TotalInstallmentAmountSubText', {
                    num: scheduledInstallments?.installments?.length || 0,
                    amount: formatCurrency(
                      scheduledInstallments?.installments?.[0]
                        ?.installmentAmount || 0,
                    ),
                  })
                : ''}
            </Box>
          </Tooltip>
        }
        amount={payableAmount}
      />
      <Box sx={{ backgroundColor: tokens.colors.white }}>
        <Box sx={styles.detailContainer}>
          <Box sx={styles.detailSection}>
            <Box sx={styles.details}>
              <>
                <Box sx={styles.detailRow}>
                  <Box sx={styles.detailLabel}>
                    {t('Invoicing.PaymentSuccessPage.InstallmentPlan')}
                  </Box>
                  <Box sx={styles.detailLabelContent}>
                    {!isMobile && scheduledInstallments?.installmentPlan}
                    {isMobile &&
                      scheduledInstallments?.installmentPlan
                        .split('/')[0]
                        .trim()}
                    {isMobile && (
                      <Box sx={{ marginTop: '0.5em' }}>
                        (
                        {(scheduledInstallments?.installments?.length || 0) >= 1
                          ? t('Invoicing.TotalInstallmentAmountSubText', {
                              num:
                                scheduledInstallments?.installments?.length ||
                                0,
                              amount: formatCurrency(
                                scheduledInstallments?.installments?.[0]
                                  ?.installmentAmount || 0,
                              ),
                            })
                          : ''}
                        )
                      </Box>
                    )}
                  </Box>
                </Box>
                <hr aria-hidden="true" />
              </>
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  <Box>{t('Invoicing.Installment')}</Box>
                  <Box>{t('Invoicing.PaymentDates')}</Box>
                </Box>
                <Box sx={styles.detailLabelContent2}>
                  {scheduledInstallments?.installments?.map(
                    (installment, index) => (
                      <React.Fragment
                        key={`transaction-${installment.transactionId}`}>
                        <Box>
                          {t(
                            'Invoicing.PaymentSuccessPage.TransactionDateFormat',
                            {
                              date:
                                index === 0 && nextPaymentDate
                                  ? nextPaymentDate
                                  : installment.scheduledDate,
                            },
                          )}
                        </Box>
                        {!isMobile && (
                          <>
                            <Box style={{ paddingLeft: '1rem' }}>
                              {formatCurrency(installment.installmentAmount)}
                            </Box>
                            <Box
                              style={{
                                paddingLeft: '1rem',
                                fontStyle: 'italic',
                              }}>
                              {`(payment ${index + 1} of ${
                                scheduledInstallments?.installments?.length
                              })`}
                            </Box>
                          </>
                        )}
                      </React.Fragment>
                    ),
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PaymentMethod')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaymentMethodDisplay(
                    scheduledInstallments?.paymentMethod,
                    scheduledInstallments?.accountNumber,
                  )}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.PayerName')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {scheduledInstallments?.payer}
                </Box>
              </Box>
              <hr aria-hidden="true" />
              <Box sx={styles.detailRow}>
                <Box sx={styles.detailLabel}>
                  {t('Invoicing.PaymentSuccessPage.Invoices')}
                </Box>
                <Box sx={styles.detailLabelContent}>
                  {getPaidInvoices(invoicePayments).map((invoice) => (
                    <Box sx={styles.InvoicesDetails}>
                      {!isMobile && <Box>{invoice.customerName}</Box>}
                      <Box>{invoice.invoices}</Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {isDesktop && <Box sx={styles.autopay} />}
          </Box>
          {!isMobile && (
            <Button
              data-testid="Btn_Invoicing_ViewPaidInvoices"
              onClick={handleTogglePaidInvoices}
              aria-atomic
              aria-expanded={invoiceViewState}
              aria-controls="invoicesPaymentTable"
              aria-live="polite"
              sx={styles.textButton}>
              <Box component="span" sx={styles.toggle}>
                {invoiceViewState
                  ? t(
                      'Invoicing.PaymentSuccessPage.HideInstallmentPaidInvoiceDetails',
                    )
                  : t(
                      'Invoicing.PaymentSuccessPage.ViewInstallmentPaidInvoiceDetails',
                    )}
              </Box>
              <FontAwesomeIcon
                icon={invoiceViewState ? faAngleDoubleUp : faAngleDoubleDown}
              />
            </Button>
          )}
        </Box>
        {!isMobile && invoiceViewState && (
          <ScheduledPaymentSuccessTable invoicePayments={invoicePayments} />
        )}
      </Box>
      <CancelScheduleInvoicesDialog
        open={isCancelDialogOpen}
        onClose={() => setIsCancelDialogOpen(false)}
        onConfirm={() => handleConfirmCancelDialog()}
        scheduledInvoicesData={scheduledInvoicesData}
        selectedPlanId={scheduledInstallments?.installmentPlanId ?? 0}
        isScheduled={IsScheduledPayment}
        planDate={format(new Date(), 'MM/dd/yyyy')}
      />
      <ScheduledPaymentDateChangeDialog
        open={openChangeDateDialog}
        onClose={() => {
          setOpenChangeDateDialog(false)
          fetchPlanDetails(scheduledInstallments?.installmentPlanId ?? 0)
        }}
        paymentInstallmentPlanId={scheduledInstallments?.installmentPlanId ?? 0}
        planName={
          t('Invoicing.PaymentSuccessPage.ScheduledPaymentDateSelect', {
            name: format(new Date(), 'MM/dd/yyyy'),
          }) ?? ''
        }
        existingPaymentDate={
          scheduledInstallments?.installments?.[0].scheduledDate ?? new Date()
        }
      />
    </>
  )
}

export default InstallmentPaymentSuccess
