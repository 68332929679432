import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Slider, styled } from '@mui/material'
import { formatCurrencyNoDecimal } from '../utils/formatters'
import { tokens } from '../../styles/materialTheme'

const StyledSlider = styled(Slider)(() => ({
  color: tokens.colors.rsmBlue.primary,
  '& .MuiSlider-thumb': {
    backgroundColor: tokens.colors.rsmBlue.primary,
    // // To add $ symbol in thumb use belwo styles but need an image and measured cropping
    // content: '"$"',
    // backgroundImage: `url("https://picsum.photos/20/20")`,
  },
  '& .MuiSlider-rail': {
    color: tokens.colors.gray,
  },
}))

const style = {
  sliderContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  sliderBox: {
    width: '90%',
    padding: '0 0 0 1rem',
  },
  header: {
    fontStyle: 'italic',
    fontSize: '1rem',
    padding: '0.125rem 0 0.5rem 0.5625rem',
  },
}

interface CurrencyRangeSliderProps {
  minMaxRange: number[]
  currentValues: number[]
  currency: string
  handleChange: (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => void
}

const CurrencyRangeSlider = ({
  minMaxRange,
  currentValues,
  currency,
  handleChange,
}: CurrencyRangeSliderProps) => {
  const { t } = useTranslation()
  const [min, max] = minMaxRange
  const [from, to] = currentValues

  const rangeHeader = useMemo(() => {
    const toValue =
      to === max ? t(`Invoicing.Max`) : formatCurrencyNoDecimal(to, currency)
    return `${formatCurrencyNoDecimal(from, currency)} - ${toValue}`
  }, [currency, currentValues])

  const labels = useMemo(
    () => [
      {
        value: min,
        label: `${formatCurrencyNoDecimal(min, currency)}`,
      },
      {
        value: max,
        label: t(`Invoicing.Max`),
      },
    ],
    [currency, minMaxRange],
  )

  const step = useMemo(() => {
    const delta = max - min
    if (delta <= 100) return Math.round(delta / 10)
    return Math.round(delta / 100)
  }, [minMaxRange])

  const valueLabelFormat = (value: number) =>
    value === max ? t(`Invoicing.Max`) : value

  return (
    <Box sx={style.sliderContainer}>
      <Typography variant="subtitle1" component="span" sx={style.header}>
        {t(`Invoicing.FilterGroups.amountRangeHeader`)}
        {rangeHeader}
      </Typography>
      <Box
        title={t(`Invoicing.FilterGroups.amountRangeTooltip`)}
        sx={style.sliderBox}>
        <StyledSlider
          getAriaLabel={() => t(`Invoicing.FilterGroups.amountRangeHeader`)}
          getAriaValueText={() => rangeHeader}
          min={min}
          step={step}
          max={max}
          marks={labels}
          valueLabelFormat={valueLabelFormat}
          valueLabelDisplay="auto"
          value={[from, to]}
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

export default CurrencyRangeSlider
